import { trigger, transition, style, animate, keyframes } from "@angular/animations";


export const flyIcon =
  trigger('flyIconLeaveEnter', [
        transition('send => recive', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('300ms', 
            keyframes([
              style({opacity: 1, offset: 0}),
              style({opacity: 1, transform: 'translateY(-50%) translateX(50%)', offset: 0.5}),
              style({opacity: 0, transform: 'translateY(-100%) translateX(100%)', offset: 1})
            ])),
        ]
        ),
        transition('recive => send', [
          style({transform: 'translateY(100%) translateX(-100%)', opacity: 0}),
          animate('300ms', 
            keyframes([
              style({opacity: 0, offset: 0}),
              style({opacity: 0, transform: 'translateY(50%) translateX(-50%)', offset: 0.5}),
              style({opacity: 1, transform: 'translateY(0) translateX(0)', offset: 1})
            ])),
        ])
      ]
  );