import { Component, OnInit } from '@angular/core';
import { BasicModalService, Modal } from './basic-modal.service';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss'],
  host: {
    class: 'modal'
  }
})
export class BasicModalComponent implements OnInit {

  public content: Modal = null;

  constructor(private modalService: BasicModalService) { }

  ngOnInit() {
    this.modalService.content.subscribe(content => {
      this.content = content;
    })
  }

  exitModal(){
    this.modalService.setModal(null)
  }

}
