<div class="columns is-gapless">

  <div class="column">

    <div class="columns is-full-width is-multiline content-half-box">
      <div class="column is-full center-slideshow-content">
        <h2 class="first-part">Oops... Vi har litt problemer med å vise deg innholdet.</h2>
      </div>
      <div class="column is-half">
        <p><strong>Dette var litt flaut...</strong></p>
        <p>Vi klarer ikke å tilkoble våre tjenester, og dermed får vi ikke vist deg innholdet.</p>
        <p>Du kan forsøke å laste inn siden på nytt. Hvis det ikke skulle fungere kan du kontakt oss på <span>23 20 79 20</span>, eller prøve å laste inn siden på nytt senere.</p>
        <m-button [label]="'Last siden på nytt'" [link]="'/'" [type]="'blue'" [target]="'_self'" [icon]="'refresh-cw'" [mobile]="false">
        </m-button>
      </div>
      <div class="column is-half">
          <p>
            Ønsker du å søke husleiegaranti kan du gå direkte til denne siden
          </p>
          <m-button [label]="'Søk leietakerbevis'" [link]="minSide" [type]="'primary'"  [icon]="'link'"
            [mobile]="false">
          </m-button>
        <p>
          Ønsker du å søke leietakerbevis kan du gå direkte til denne siden
        </p>
        <m-button [label]="'Søk leietakerbevis'" [link]="minSide" [type]="'primary'"  [icon]="'link'"
          [mobile]="false">
        </m-button>
      </div>
    </div>
  </div>
</div>
