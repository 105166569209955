<ng-template [ngIf]="!link" [ngIfElse]="linka">
  <div class="button-wrap" [id]="cleanString(label)">
    <button type="submit" class="btn" [attr.aria-label]="label" class="btn {{type}}" [class.outline]="outline"
      (click)="onClickbutton($event)">
      <span [class.mobile-friendly]="mobile">
        <span class="text button-content">{{label}}</span>
        <i-feather *ngIf="icon" [name]="icon" class="icon-normal-size button-content" [class.animate]="iconTrigger" [@flyIconLeaveEnter]="iconTrigger ? 'recive' : 'send'"></i-feather>
      </span>
    </button>
  </div>
</ng-template>

<ng-template #linka>
  <div class="button-wrap" [id]="cleanString(label)">
    <a href="{{link}}" class="btn" class="btn {{type}}" [class.outline]="outline" target="target" [attr.aria-label]="label">
      <span [class.mobile-friendly]="mobile">
        <span class="text button-content">{{label}}</span>
        <i-feather *ngIf="icon" [name]="icon" class="icon-normal-size button-content" [@flyIconLeaveEnter]="iconTrigger ? 'send' : 'recive'"></i-feather>
      </span>
    </a>
  </div>
</ng-template>
