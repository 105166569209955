<div class="footer-news-letter">
  <div class="columns is-full-width is-centered">
    <div class="column is-half">
      <h3>Hold deg oppdatert</h3>
      <div class="columns is-gapless is-multiline is-mobile">

        <div class="column is-two-thirds">
          <label for="email" [hidden]="true">Epost</label>
          <input type="email" name="email" aria-label="Legg til din epost for nyhetsbrev" placeholder="Fyll inn din epost" [formControl]="email">
        </div>        

        <div class="column is-one-third full-width-btn">
          <m-button (onClick)="sendToMailChimp()" [iconTrigger]="isLoading" [label]="'Meld meg på'" [type]="'primary'" [icon]="'send'"
            [mobile]="true"></m-button>
        </div>

        <div class="column is-12 min-height">
          <div *ngIf="error" @fadeUpDownEnterLeave class="response-message" [innerHTML]="error"></div>
        </div>    
        
        <div class="column is-12 min-height" @fadeUpDownEnterLeave *ngIf="email.valid">
          <p>Ved å skrive inn e-postadressen din ovenfor og klikke på «Meld meg på <i-feather name="Send" style="width: 1em; height: 1em;"></i-feather>» samtykker du til at vi sender deg informasjon om nye produkter og tilbud og at vi bruker e-postadressen din til å gjøre dette.</p>
        </div>

      </div>
    </div>

  </div>
</div>

<footer class="desktop-footer">
  <div class="columns is-multiline">
    <div class="column is-12">
    </div>
    <div class="column">
      <div class="footer-image" >
        <div class="back-to-top">
          <img src="assets/images/svg/logo-footer.svg" alt="Garantiportalen">
        </div>  
        <div class="sosial-wrapper">
          <div class="sosial sosial-facebook">
            <a href="https://www.facebook.com/garantiportalen" target="_blank"><i-feather name="facebook" class="icon-normal-size"></i-feather></a>
          </div>
          <div class="sosial sosial-instagram">
            <a href="https://www.instagram.com/garantiportalen.no" target="_blank"><i-feather name="instagram" class="icon-normal-size"></i-feather></a>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <h3>Ta kontakt med oss</h3>
      <ul>
        <li>hei@garantiportalen.no</li>
        <li>23 20 79 00</li>
        <li>Rådhusgata 11, 0151 Oslo</li>
      </ul>
    </div>
    <div class="column">
      <h3>Oversikt</h3>
      <ul>
        <li><a [routerLink]="['/gp', 'personvern']" routerLinkActive="router-link-active" >Personvern</a></li>
        <li><a [routerLink]="['/gp', 'bruksvilkar']" routerLinkActive="router-link-active" >Vilkår</a></li>
        <li><a href="https://matrixinsurance.no">Matrix Insurance</a></li>
        <li><a href="https://portal.matrixinsurance.no/garantiportalen" target="_blank">Min side</a></li>
      </ul>
    </div>
    <div class="column">
    </div>
  </div>
  <div class="columns bottom">
    <p class="column copyright">
      Garantiportalen er et produkt levert av Matrix Insurance AS i samarbeid med General Insurance Company (IGI)</p>
  </div>
</footer>
