// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  links: {
    minSide: 'https://portal.matrixinsurance.no/garantiportalen',
    bringAPI: 'https://api.bring.com/shippingguide/api/postalCode.json' 
  },
  db: {
    path: 'https://breitech.no/matrix/matrix-db/',
    imagePath: 'https://breitech.no/matrix/',
    apiKey: 'ba201ba672b569dced1b90a33c34a6',
    apiAccountKey:'account-db84671757cf282ee7179dac4b7d99'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
