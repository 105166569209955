import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { ButtonModule } from '../shared/components/buttons/button.module';
import { NavModule } from '../shared/components/nav/nav.module';
import { FooterComponent } from './footer/footer.component';
import { MailchimpService } from './services/mailchimp.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { VisitedComponent } from './components/visited/visited.component';
import { IconsModule } from '../shared/icons/icons.module';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    VisitedComponent
  ],
  imports: [
    BrowserModule,
    ButtonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NavModule,
    HttpClientJsonpModule,
    HttpClientModule,
    FormsModule,
    IconsModule
  ],
  providers: [
    MailchimpService
  ],
  bootstrap: [],
  exports: [
    HeaderComponent,
    FooterComponent,
    VisitedComponent,
    FormsModule
  ]
})
export class CoreModule { }
