export const environment = {
  production: true,
  links: {
    minSide: 'https://portal.matrixinsurance.no/garantiportalen',
    bringAPI: 'https://api.bring.com/shippingguide/api/postalCode.json' 
  },
  db: {
    path: 'https://breitech.no/matrix/matrix-db/',
    imagePath: 'https://breitech.no/matrix/',
    apiKey: 'ba201ba672b569dced1b90a33c34a6',
    apiAccountKey:'account-db84671757cf282ee7179dac4b7d99'
  }
};