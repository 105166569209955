import { Component, OnInit, Inject } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { Router, ActivatedRoute } from '@angular/router';

declare let fbq: Function;

@Component({
  selector: 'app-consent-banner',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  public showBanner: boolean;
  public showNes: boolean = false;
  public showSta: boolean = false;
  public statCheck: boolean = false;

  public constructor(
    private core: CoreService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject('document') private document,
    @Inject('window') private window
  ) {
    const consent = this.getCookie('cookieconsent');
    if(consent === 'allow'){
      console.log('Call allow')
      //fbq('init', '301594984539726');
      fbq('track', 'PageView');
    }
  }

  public ngOnInit() {
    const consent = this.getCookie('cookieconsent');
    const disallow = this.getCookie('disallowcookieconsent');
    if (consent === 'allow' || disallow === 'disallowed') {
      this.showBanner = false;
    } else {
      this.showBanner = true;
    }

  }


  private getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1);      
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);      
    }
    return '';
  }


  private setCookie(cname, cvalue, exdays) {
    return new Promise(resolve => {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      const expires = 'expires=' + d.toUTCString();
      this.document.cookie = cname + '=' + cvalue + ';' + expires;
      resolve()
    });
  }


  public removeBanner(){
    this.setCookie('disallowcookieconsent', 'disallowed', 365)
    .then(() => {
      this.document.cookie = 'cookieconsent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      this.showBanner = false;
    })
  }


  public saveSettings() {
    this.setCookie('cookieconsent', 'allow', 365)
    .then(() => {
      const d = new Date();
      d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
      this.document.cookie = 'disallowcookieconsent= ; expires = ' + d.toUTCString();
      this.reloadPage();
    })
  }

  private reloadPage(){
    this.core.visit.subscribe(c => {
      if(c){
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            utm_source: c.name.toLocaleLowerCase()
          },
          queryParamsHandling: 'merge',
        })
        .then(() => this.showBanner = false);
      } else this.window.location.reload();
    })
  }


}
