<header>

  <nav class="header-box">
    <m-nav></m-nav>
  </nav>


  <div class="header-box logo">
    <a class="header-image" href="#" [routerLink]="path">
      <img src="assets/images/svg/logo-flat.svg" alt="Garantiportalen">
    </a>
  </div>


  <div class="header-box header-buttons">
    <m-button [link]="minSide" [label]="'Gå til MinSide'" [type]="'primary'" [icon]="'log-in'" [mobile]="true">
    </m-button>
  </div>

</header>
