import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItems, Visit } from '../interface/menu';

@Injectable({providedIn: 'root'})

export class CoreService {
  
  public menuElements: BehaviorSubject<MenuItems> = new BehaviorSubject(null);


  constructor(private httpClient: HttpClient, private router: Router) {
    this.getMenuitems()
  }

 
  /*Fetch all menu items*/
  getMenuitems(){
    let t = this.httpClient.get<any>(environment.db.path + 'api/collections/get/gpmenu?token=' + environment.db.apiKey)
    .toPromise()
    .then(data => {
      if(data){
        let res: MenuItems = {
          main: [],
          subMenu: [],
          links: []
        }
        data.entries.forEach(e => {
          e.disabled = false;
          if(e.menulevel == 1) res.main.push(e)
          else if(e.menulevel == 2) res.subMenu.push(e)
          else if(e.menulevel == 3) res.links.push(e)
        });
        res.main = res.main.sort((a: any,b: any) => parseInt(a.sort) > parseInt(b.sort) ? 1 : -1);
        res.subMenu = res.subMenu.sort((a: any,b: any) => parseInt(a.sort) > parseInt(b.sort) ? 1 : -1);
        this.menuElements.next(res)
      }
    }).catch(error => {
      this.router.navigate(['oops-en-feil-har-oppstatt'])
    });
  }

  private _isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOpen: Observable<boolean> = this._isOpen.asObservable();
  /**
   * Toggle menu state
   */
  toggleOpenMenu(){
    let prev = this._isOpen.getValue();
    this._isOpen.next(prev = !prev);
  }
  


  private _visit: BehaviorSubject<Visit> = new BehaviorSubject(null);
  public visit: Observable<Visit> = this._visit.asObservable();

  /**
   * Set visit
   * @param name 
   * @param path 
   */
  visitDiscount(name: string, path: string){
    let show = false;
    if(this._visit.getValue()) show = this._visit.getValue().hide;
    this._visit.next({
      name: name,
      path: path,
      hide: show
    })
  }

  /**
   * Remove visit
   */
  removeDiscount(){
    let data = this._visit.getValue();
    data.hide = true;
    this._visit.next(data);
  }


  toggleMenuItemVisibility(element: string, boolean = false){
    let counter = 0;
    let a = setInterval(() => {
      if(this.menuElements.getValue()){
        let menu = this.menuElements.getValue();
        if(menu.hasOwnProperty('main')){
          //menu.main.find(o => o.path == element && o.hasOwnProperty('disabled')).disabled = boolean;
          this.menuElements.next(menu);  
        }
        clearInterval(a);
      }else if(counter > 60) clearInterval(a);
      counter++;
    }, 200);
  }


}
