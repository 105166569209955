import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Modal {
  header: string;
  text: string;
}

@Injectable({ providedIn: 'root' })

export class BasicModalService {

  public content: BehaviorSubject<null|any> = new BehaviorSubject(null);
  
  constructor() {
  }

  setModal(modal: Modal){
    this.content.next(modal)
  }

  

}