<div class="toggle-menu">
  <div class="hamburger hamburger--collapse" [class.is-active]="t.isOpen | async" (click)="t.toggleOpenMenu()">
    <div class="hamburger-box">
      <div class="hamburger-inner"></div>
    </div>
  </div>
</div>

<div class="mobile-menu-wrapper" *ngIf="t.menuElements | async as menu" [class.is-active-mobile]="t.isOpen | async">
  <div class="main-menu">
    <ul>
      <ng-container *ngFor="let item of menu.main">
        <li *ngIf="!item?.disabled" (click)="t.toggleOpenMenu()">      
          <a [routerLink]="item.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [attr.aria-label]="item.name">
            {{ item.name }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="sub-menu">
    <ul>
      <ng-container *ngFor="let item of menu.subMenu">
        <li *ngIf="!item?.disabled" (click)="t.toggleOpenMenu()">      
          <a [routerLink]="item.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [attr.aria-label]="item.name">
            {{ item.name }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>