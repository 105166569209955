import { Component, Input, ElementRef } from '@angular/core';
import { MenuItems } from '../../../core/interface/menu';
import { CoreService } from '../../../core/services/core.service';


@Component({
  selector: 'm-nav',
  templateUrl: './nav.component.html',
  host: {
    '(document:click)': 'onClick($event)',
  }
})


export class NavComponent {
  @Input() icon: boolean = false;
  @Input() text: boolean = true;

  public isOpen: boolean = false;
  public menuItems: MenuItems = null;

  constructor(
    public t: CoreService, 
    private _eref: ElementRef
  ) {}

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.isOpen == true){
      this.t.toggleOpenMenu();
    }
   }

}

