import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'visited-view',
  templateUrl: './visited.component.html',
  styleUrls: ['./visited.component.scss']
})
export class VisitedComponent implements OnInit {

  public hideOffer: boolean = true;

  constructor(
    public core: CoreService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((e:any) => {
      if(e.url.includes('rabatter') || e.url.includes('utleiemegler')) this.hideOffer = true
      else if(this.hideOffer == true) this.hideOffer = false
    });
  }


  remove(){
    this.hideOffer = true;
  }

  ngOnInit() {
  }

}
