import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})

/*
Connect to MailChimp with group-set
*/

export class MailchimpService {
  
  constructor(private httpClient: HttpClient) {
    
  }
    
}