import {
  trigger,
  transition,
  style,
  query,
  group,
  animate,
  keyframes
} from '@angular/animations';


export const pulseAnimation = trigger('counterChange', [
  transition(':increment, :decrement', [
    style('*'),
    animate(
      '400ms',
      keyframes([
        style({ transform: 'scale(1)', offset: 0 }),
        style({ transform: 'scale(1.2)', offset: 0.5 }),
        style({ transform: 'scale(1)', offset: 1 })
      ])
    )
  ])
])

export const fadeDownUp =
  trigger('fadeUpDownEnterLeave', [
        transition(':enter', [
          style({transform: 'translateY(-100%)', opacity: 0}),
          animate('100ms', 
            keyframes([
              style({opacity: 0, offset: 0}),
              style({opacity: 0, transform: 'translateY(-50%)', offset: 0.5}),
              style({opacity: 1, transform: 'translateY(-0)', offset: 1})
            ])),
        ]
        ),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('100ms', 
            keyframes([
              style({opacity: 1, offset: 0}),
              style({opacity: 1, transform: 'translateY(-50%)', offset: 0.5}),
              style({opacity: 0, transform: 'translateY(-100%)', offset: 1})
            ])),
        ])
      ]
  );

export const fadeIn =
  trigger('routeAnimations', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', 
            keyframes([
              style({ transform: 'scale(1.5)', opacity: 0, offset: 0 }),
              style({ transform: 'scale(1.3)', opacity: 0.8, offset: 0.1 }),
              style({ transform: 'scale(1)', opacity: 0.8, offset: 0.5 }),
              style({ transform: 'translateX(0)', opacity: 1, offset: 1 })
            ]),
          )]
        ),
        transition(':leave', [
          style({transform: 'translateX(0)', position: 'absolute', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
  );


export const fader =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        }),
      ]),
      // Animate the new page in
      query(':enter', [
        animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
      ])
    ]),
]);


export const slider =
  trigger('routeAnimations', [
    transition('* => isLeft', slideTo('left') ),
    transition('* => isRight', slideTo('right') ),
    transition('isRight => *', slideTo('left') ),
    transition('isLeft => *', slideTo('right') )
  ]);


function slideTo(direction) {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        [direction]: 0,
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ [direction]: '-100%'})
    ]),
    group([
      query(':leave', [
        animate('600ms ease', style({ [direction]: '100%'}))
      ], optional),
      query(':enter', [
        animate('600ms ease', style({ [direction]: '0%'}))
      ])
    ]),
  ];
}


export const transformer =
  trigger('routeAnimations', [
    transition('* => isLeft', transformTo({ x: -100, y: -100, rotate: -720 }) ),
    transition('* => isRight', transformTo({ x: 100, y: -100, rotate: 90 }) ),
    transition('isRight => *', transformTo({ x: -100, y: -100, rotate: 360 }) ),
    transition('isLeft => *', transformTo({ x: 100, y: -100, rotate: -360 }) )
]);


function transformTo({x = 100, y = 0, rotate = 0}) {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`})
    ]),
    group([
      query(':leave', [
        animate('600ms ease-out', style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`}))
      ], optional),
      query(':enter', [
        animate('600ms ease-out', style({ transform: `translate(0, 0) rotate(0)`}))
      ])
    ]),
  ];
}