import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NavComponent } from './nav.component';
import { RouterModule, Routes } from '@angular/router';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'husleie',
    pathMatch: 'full',
  }
];

@NgModule({
  declarations: [
    NavComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot( appRoutes )
  ],
  exports: [
    NavComponent,
    RouterModule
  ]
})
export class NavModule { }
