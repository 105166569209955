<div *ngIf="content" class="modal-bg" (click)="exitModal()"></div>

<section *ngIf="content" class="modal-wrapper">
  <div class="modal-content">
    <div class="modal-exit" (click)="exitModal()">
      <i-feather name="x"></i-feather>
    </div>
    <div class="modal-content-input">
      <h2>{{content.header}}</h2>
      <div class="modal-text" [innerHTML]="content.text">
      </div>
    </div>
  </div>
</section>