import { Component } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { fadeDownUp } from '../core.animations';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'core-footer',
  templateUrl: './footer.component.html',
  animations: [ fadeDownUp ]
})
export class FooterComponent {
  title = 'app';
  isActive = false;
  result

  isLoading: boolean = false
  email: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.email]));

  submitted = false;
	mailChimpEndpoint = 'https://matrixinsurance.us19.list-manage.com/subscribe/post-json?u=4ead40b04fad3c9a5de14d7a2&amp;id=d8c0df1d75&subscribe=Subscribe&';
  error = '';
  
  constructor(private http: HttpClient){
  }

  sendToMailChimp(){
    this.isLoading = true;
    this.error = '';
      const params = new HttpParams()
        .set('EMAIL', this.email.value)
        .set('interests', 'Homepage:True')
        .set('b_123abc123abc123abc123abc123abc123abc', '');

      const mailChimpUrl = this.mailChimpEndpoint + params.toString();
      this.http.jsonp<any>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.submitted = true;
          this.isLoading = false;
          this.error = 'Takk for din påmelding'
        }
        else {
          this.error = 'Oops, en feil har oppstått.';
          this.isLoading = false;
        }
      }, error => {
        this.isLoading = false;
        this.error = 'Sorry, an error occurred.';
      });
  }

}
//"morten@breitech.no is already subscribed to list Matrix Insurance. <a href="https://matrixinsurance.us19.list-manage.com/subscribe/send-email?e=bW9ydGVuQGJyZWl0ZWNoLm5v&u=4ead40b04fad3c9a5de14d7a2&id=d8c0df1d75">Click here to update your profile</a>"
//{result: "error", msg: "testfunction3@breitech.no is already subscribed to…d8c0df1d75">Click here to update your profile</a>"}