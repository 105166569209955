import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoreService } from '../../../core/services/core.service';

@Injectable({
  providedIn: 'root'
})
export class DealsService {

  private _place: BehaviorSubject<string> = new BehaviorSubject(null);
  public place: Observable<string> = this._place.asObservable();

  private _id: BehaviorSubject<string> = new BehaviorSubject(null);
  public id: Observable<string> = this._id.asObservable();

  private _rate: BehaviorSubject<number> = new BehaviorSubject(0.035);
  public rate: Observable<any> = this._rate.asObservable();

  private _isLoadingRate: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoadingRate: Observable<boolean> = this._isLoadingRate.asObservable();
  
  private _fee: BehaviorSubject<number> = new BehaviorSubject(390);
  public fee: Observable<number> = this._fee.asObservable();

  constructor(
    private http: HttpClient,
    private core: CoreService
  ) { }


  checkAgent(code){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    }
    let ref = 'https://breitech.no/project/gp/api/apply/check_agent.php?reqkey=' + code;
    return this.http.get<any>(ref, httpOptions)
  }

  setDefaultRate(){
    this._rate.next(0.035);
  }

  getPriceFormWims(id, fee: number){
    const httpOptions = { headers: new HttpHeaders({'Content-Type':  'application/json'}) };
    let ref = 'https://breitech.no/backbone/api/rates/guarantees/landord?resellerId=' + id;
    return new Promise(resolve => {

      if(id == this._id.getValue()) {
        resolve();
        return;
      };
      this._isLoadingRate.next(true);
      this.http.get<any>(ref, httpOptions).toPromise()
      .then((res) => {
        if(res.rate) this._rate.next(res.rate);
        this._isLoadingRate.next(false);
        this._id.next(id);
        resolve();
      })
      .catch((e) => {    
        this._isLoadingRate.next(false);
        resolve()
      })
    })
  }

  setPlace(place?: string){
    if(place == 'utleiemegler') this.core.toggleMenuItemVisibility('/husleie', true);
    else this.core.toggleMenuItemVisibility('/husleie', false);
    this._place.next(place ? place : null);
  }
  
}
