import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'error-app',
    templateUrl: 'error.component.html'
})

export class ErrorComponent implements OnInit {
    
  minSide = environment.links.minSide

  constructor() { }

  ngOnInit() { }
}