import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

declare let ga: Function;
declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {

  constructor(public router: Router, title: Title) {    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event.urlAfterRedirects)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'pageview',
          'pagePath': event.urlAfterRedirects,
          'pageTitle': title.getTitle()
        });
      }
    });
  }

}
