import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ButtonModule } from './shared/components/buttons/button.module';
import { HomeComponent } from './modules/home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { NavModule } from './shared/components/nav/nav.module';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorComponent } from './modules/garantiportalen/pages/error/error.component';

import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import localeNbExtra from '@angular/common/locales/extra/nb';

import { CoreService } from './core/services/core.service';
import { SeoService } from './core/services/seo.service';
import { BasicModalComponent } from './core/components/basic-modal/basic-modal.component';
import { RequestCache } from './modules/content/service/cache.service';
import { CachingInterceptor } from './modules/content/service/cache-interceptor.service';
import { ConsentComponent } from './core/components/consent/consent.component';
import { IconsModule } from './shared/icons/icons.module';

registerLocaleData(localeNb, 'nb_NO', localeNbExtra);

const appRoutes: Routes = [
  { 
    path: '',          
    component: HomeComponent,
    children: [
      {
        path: 'husleie',
        pathMatch: 'full',
        redirectTo: ''
      },
      {
        path: 'husleie/leietaker',
        pathMatch: 'full',
        redirectTo: ''
      },
      { 
        path: '',
        loadChildren: () => import('./modules/husleiegaranti/husleiegaranti.module').then(m => m.HusleiegarantiModule)
      },
      { 
        path: 'gp',               
        loadChildren: () => import('./modules/garantiportalen/garantiportalen.module').then(m => m.GarantiportalenModule)
      },
      {
        path: 'oops-en-feil-har-oppstatt',
        component: ErrorComponent
      },
      {
        path: 'innhold',
        loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule)
      },
      {
        path: 'rabatter',
        loadChildren: () => import('./modules/deals/deals.module').then(m => m.DealsModule),
        data: {name: 'rabatter'}
      },
      {
        path: 'utleiemegler',
        loadChildren: () => import('./modules/deals/deals.module').then(m => m.DealsModule),
        data: {name: 'utleiemegler'}
      }
    ]
  },
  {
    path: 'utleier-aksept',
    loadChildren: () => import('./modules/utleier/utleier.module').then(m => m.UtleierModule)
  },
  {
    path: 'agent',
    loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];


@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    BasicModalComponent,
    HomeComponent,
    ConsentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CoreModule,
    ButtonModule,
    NavModule,
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'}),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    IconsModule
  ],
  providers: [CoreService, SeoService,
    RequestCache,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: CachingInterceptor, 
      multi: true 
    },
    {
      provide: 'window',
      useValue: window,
    },
    {
      provide: 'document',
      useValue: document,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
