import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'core-header',
  templateUrl: './header.component.html',
  host: {
    class: 'header-section'
  }
})
export class HeaderComponent {
  path = '/';
  title = 'app';
  isActive = false;
  minSide = environment.links.minSide

  constructor(
    public core: CoreService
  ){
    this.core.visit.subscribe(visit => {
      if(visit) this.path = visit.path;
      else '/';
    })
  }

  changeMenuState(){
    this.isActive = !this.isActive;       
  }

}
