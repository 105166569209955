import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DealsService } from './modules/deals/service/deals.service';
import { CoreService } from './core/services/core.service';
import { AnalyticsService } from './core/services/analytics.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Garantiportalen';

  constructor(
    public router: Router, 
    private t: Title,
    private dealService: DealsService,
    private route: ActivatedRoute,
    public core: CoreService,
    public analytics: AnalyticsService
  ){
    this.route.queryParams.subscribe(r => {
      if(r.hasOwnProperty('utm_source')) {
        this.dealService.checkAgent(r.utm_source).toPromise()
        .then((a) => {
          if(a.length > 0) this.dealService.place.subscribe(place => this.core.visitDiscount(a[0].name, '/'+ (place ? place : 'rabatter') +'/'+r.utm_source));
        })
        .catch(e => {})
      }
    })
  }
  

  

}
