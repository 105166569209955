import { Component, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { flyIcon } from './button.animations';

@Component({
  selector: 'm-button',
  templateUrl: './button.component.html',
  animations: [ flyIcon ]
})

export class ButtonComponent {
  
  /*
  Button types:
    - default
    - primary
    - success
    - danger
    - warning
    - light
  */

  @Input() label : string = 'Missing label';
  @Input() type :   string = 'default';
  @Input() icon :   string = null;
  @Input() mobile : boolean = false;
  @Input() outline : boolean = false;
  @Input() link :   string = null;
  @Input() target : string = '_blank';
  public cleanId : string = this.cleanString(this.label) 

  @Input() iconTrigger : boolean = null;

  @Output() onClick = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges) {
    //this.iconTrigger 
    this.cleanString(this.label) 
  }

  cleanString(label: string) : string{
    return label.replace(/[^æøå\w -]+/g, '').replace(/ +/g, '-').replace(' ', '-').toLocaleLowerCase();
  }

  clickButton(){
    this.iconTrigger = !this.iconTrigger;
  }

  onClickbutton(event) {
    this.onClick.emit(event);
  }
}

