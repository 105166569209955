<div *ngIf="showBanner" class="consent-banner">
  <div class="options">
    <p>
      Garantiportalen er en del av Matrix Insurance, som er ansvarlig for dine data. Vi bruker informasjonskapsler for å sørge for at siden er så bra som mulig og forbedre brukeropplevelsen ved å samle statistikk. 
      Du kan når som helst endre på dine innstillinger som du finner under <a routerLink="/gp/personvern">Personvern</a>
    </p>
    <div class="options-button">
      <div class="button-row">
        <button class="btn blue" (click)="saveSettings()">Godta</button>      
        <button class="btn blue outline" (click)="removeBanner()" routerLink="/gp/personvern">Innstillinger</button>
      </div>
    </div>
  </div>
</div>